import React, { Component, RefObject } from "react";
import * as THREE from "three";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

class HeartShapeThree extends Component {
  private mount: RefObject<HTMLDivElement>;
  private camera: THREE.PerspectiveCamera;
  private renderer: THREE.WebGLRenderer;
  private frameId: number | null;

  constructor(props: {}) {
    super(props);
    this.mount = React.createRef();
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.frameId = null;
  }

  componentDidMount() {
    if (this.mount.current) {
      const width = this.mount.current.clientWidth;
      const height = this.mount.current.clientHeight;

      const scene = new THREE.Scene();
      this.renderer.setClearColor(0x000000, 0); // Set transparent background
      this.renderer.setSize(width, height);
      this.mount.current.appendChild(this.renderer.domElement);

      //add Camera
      this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
      this.camera.position.z = 20;
      this.camera.position.y = 3;

      // MTL and OBJ loader
      const mtlLoader = new MTLLoader();
      const objLoader = new OBJLoader();
      const MonkeyMtl = '/Objects/Monkey.mtl';
      const MonkeyObj = '/Objects/Monkey.obj';

      mtlLoader.load(
        MonkeyMtl,
        (materials) => {
          materials.preload();
          objLoader.setMaterials(materials);
          objLoader.load(
            MonkeyObj,
            (object) => {
              object.position.set(0, 0, 0); // set position to center
              object.scale.set(16, 16, 16);
              scene.add(object);
              console.log('Added object to scene');
            },
            (xhr) => {
              console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
            },
            (error) => {
              console.log('An error happened', error);
            }
          );
        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
        },
        (error) => {
          console.log('An error happened', error);
        }
      );

      // Ambient light
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // Soft white light
      scene.add(ambientLight);

      // Key light
      const keyLight = new THREE.DirectionalLight(new THREE.Color('hsl(30, 100%, 75%)'), 1.0);
      keyLight.position.set(-100, 0, 100);

      // Fill light
      const fillLight = new THREE.DirectionalLight(new THREE.Color('hsl(240, 100%, 75%)'), 0.75);
      fillLight.position.set(100, 0, 100);

      // Back light
      const backLight = new THREE.DirectionalLight(0xffffff, 1.0);
      backLight.position.set(100, 0, -100).normalize();

      // Lights to scene
      scene.add(keyLight);
      scene.add(fillLight);
      scene.add(backLight);

      // controls for mouse rotation
      const controls = new OrbitControls(this.camera, this.renderer.domElement);
      controls.enableDamping = true;

      this.startAnimationLoop(scene);
    }
  }

  componentWillUnmount() {
    if (this.mount.current && this.renderer.domElement) {
      this.mount.current.removeChild(this.renderer.domElement);
    }

    if (this.frameId) {
      window.cancelAnimationFrame(this.frameId);
    }
  }

  startAnimationLoop = (scene: THREE.Scene) => {
    this.renderer.render(scene, this.camera);

    // Rotate the scene
    scene.rotation.y += 0.01;

    this.frameId = window.requestAnimationFrame(() => this.startAnimationLoop(scene));
  };

  render() {
    return (
      <div
        style={{ width: "300px", height: "300px" }}
        ref={this.mount}
      />
    );
  }
}

export default HeartShapeThree;
