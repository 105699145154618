import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Home as HomeIcon, Architecture as ArchitectureIcon, PrivacyTip as PrivacyTipIcon, Article as ArticleIcon } from '@mui/icons-material'; // アイコンのインポート

import { ReactComponent as Logo } from '../../static/img/Logo.svg';

const Navigation = () => (
  <SHeader>
    <SGridContainer>
        <SLogoContainer>
        <Link to="/">
          {/* <Logo width={100} /> */}
          <p>Tokyo Designs & Arts</p>
        </Link>
        </SLogoContainer>
      <SNavigation>
        <SNavLink to="/">
          <HomeIcon />
          <span>Top</span>
        </SNavLink>
        <SNavLink to="/architecture">
          <ArchitectureIcon /> 
          <span>Architecture</span>
        </SNavLink>
        <SNavLink to="/privacy-policy">
          <PrivacyTipIcon /> <span>Privacy Policy</span>
        </SNavLink>
        <SNavLink to="/posts">
          <ArticleIcon /> <span>Posts</span> 
        </SNavLink>
      </SNavigation>
    </SGridContainer>
  </SHeader>
);

const SHeader = styled.header`
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 100;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(20px);

  @media (max-width: 767px) {
    bottom: 0;
    top: auto;
  }
`;

const SGridContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const SLogoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  p {
    margin-left: 16px;
    font-size: 16px;
    color: #1e1e1f;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const SNavigation = styled.nav`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-around;
  }
`;

const SNavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #1e1e1f;
  font-size: 16px;
  margin-right: 16px;

  svg {
    margin-right: 8px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
    svg {
      margin-right: 0;
      margin-bottom: 3px;
    }
  }
`;

export default Navigation;
