import { Outlet } from "react-router-dom";
import styled from '@emotion/styled';

function Posts() {
return (
  <SContainer>
    <Outlet />
  </SContainer>
)

}

const SContainer = styled.div`
`

export default Posts;