import React from 'react';
import styled from '@emotion/styled';

import SpringImg from '../../static/img/Spring.png';
import NishikiAE from '../../static/img/NishikiAE.gif';
import MajimunGif from '../../static/img/Majimun.gif';

function ScrollText() {
  return (
    <div>
      <SScrollList>
        <li>◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎</li>
        <li>◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎</li>
        <li>◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎◀︎</li>
      </SScrollList>
    </div>
  );
}

const SScrollList = styled.section`
  display: flex;
  list-style: none;
  padding-inline: 0;
  margin-inline: 0;
  gap: 0;
  overflow: hidden;

  > li {
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    white-space: nowrap;
    margin: 0;
    animation: marquee-left 16s linear infinite;
  }

  @media (min-width: 600px) {
    li {
      font-size: 60px;
    }
  }
  
  @keyframes marquee-left {
    100% {
      transform: translateX(-100%);
    }
  }

`

export default ScrollText;
