import React from 'react';
import styled from '@emotion/styled';

import ShuriTopCover from '../../static/img/ShuriTopCover.png';

function Architecture() {
return (
<div>
  <SContainer>

    {/* イントロダクション */}
    <SSectionTop>
      <div>
        <h2>建築モデリングで夢の空間を実現</h2>
        <p>
          プロフェッショナルな建築モデリングで、あなたのビジョンを具現化しませんか？<br />
          弊社では、3つの価格プランで様々なニーズに対応。お客様の理想の建築物を形にします。
        </p>
        <button>お見積もり</button>
      </div>
    </SSectionTop>


    {/* 価格プラン */}
    <SDarkMat>
      <SSection>
        <h2>価格プラン</h2>
        <SGridThree>
          <SGridThreeItem>
            <h3>外装モデリング</h3>
            <SPriceDiscription>
              <p>AR用途に最適</p>
              <p>外装のみ</p>
              <p></p>
            </SPriceDiscription>
            <p>制作期間: 約2週間</p>
            <p>
              <SYenSymbol>￥</SYenSymbol>100,000（税込）から
            </p>
          </SGridThreeItem>

          <SGridThreeItem>
            <h3>内外装モデリング</h3>
            <SPriceDiscription>
              <p>VR,Webに最適</p>
              <p>内装、外装含む</p>
            </SPriceDiscription>
            <p>制作期間: 約4週間</p>
            <p>
              <SYenSymbol>￥</SYenSymbol>300,000（税込）から
            </p>
          </SGridThreeItem>

          <SGridThreeItem>
            <h3>フォトリアルな再現</h3>
            <SPriceDiscription>
              <p>VR, Webに最適</p>
              <p>内装、外装含む</p>
            </SPriceDiscription>
            <p>制作期間: 約8週間</p>
            <p>
              <SYenSymbol>￥</SYenSymbol>1,000,000（税込）から
            </p>
          </SGridThreeItem>
        </SGridThree>
        <p>
          それぞれのプランには、独自の特長があります。お好みやプロジェクトの要件に合わせてお選びください。
        </p>
      </SSection>
    </SDarkMat>

    {/* 制作工程と納期 */}
    <SGrayMat>
      <SSection>
        <h2>制作工程と納期</h2>
        <p>
          弊社の建築モデリングの制作工程は、ユーザーフレンドリーかつ効率的。プロジェクトの進捗状況は随時共有し、お客様のフィードバックを大切に取り入れます。納期についても明確にお伝えし、安心してプロジェクトを進めることができます。
        </p>
        <SGridFour>
          <SGridItem>
            <h3>ヒアリング・要件定義</h3>
            <p>お客様の最終的に実現したいビジョンや予算を踏まえて、制作内容や納期やクオリティを決定します。</p>
          </SGridItem>
          <SGridItem>
            <h3>お支払い</h3>
            <p>振り込みでのお支払いをお願いしています。振り込み確認後に作業を開始させていただきます。</p>
          </SGridItem>
          <SGridItem>
            <h3>モデリングチェック</h3>
            <p>完成の途中の段階で一旦ご確認いただきます。ご要望や修正点等ある場合はこの段階でのご指摘お願いしています。これ以降の作業においての修正は別途料金がかかる場合や納期が遅れる場合がございます。</p>
          </SGridItem>
          <SGridItem>
            <h3>納品</h3>
            <p>お客様のご利用用途に合わせたファイル形式で納品いたします。</p>
          </SGridItem>
        </SGridFour>
      </SSection>
    </SGrayMat>

    {/* お客様の声 */}

    {/* CTA */}
    {/* <SSection>
      <SDivCenter>
      <h2>プロジェクトを始めませんか？</h2>
      <p>
        あなたの夢の建築物を形にしませんか？無料相談を受け付けています。お気軽にお問い合わせください。
      </p>
      <button>無料相談を申し込む</button>
      </SDivCenter>
    </SSection> */}

  </SContainer>
</div>
);
}

const SContainer = styled.div`
`

const SDarkMat = styled.div`
  background-color: #1e1e1f;
  color: #fff;
`

const SGrayMat = styled.div`
  background-color: #f5f5f5;
`

const SSection = styled.section`
  padding: 100px 16px;
  margin: 0 auto;
  max-width: 1000px;
  h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 60px;
  }
  > p {
    margin-bottom: 60px;
  }
`

const SSectionTop = styled.section`
  height: 70vh;
  padding: 0 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  // 背景画像の設定
  background-image: url(${ShuriTopCover});
  background-size: cover;
  background-position: center;

  h2 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 16px;
  }

  p {
  margin-bottom: 32px;
}
`

const SGridThree = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 36px;
  margin: 64px 0;
  // スマホ版
  @media (max-width: 767px) {
  grid-template-columns: repeat(1, 1fr);
  }
`

const SGridThreeItem = styled.div`
  > h3 {
    font-weight: bold;
    padding-bottom: 6px;
    margin-bottom: 16px;
    border-bottom: 1px solid #666;
  }
`

const SPriceDiscription = styled.div`
  min-height: 120px;
  border-bottom: 1px solid #666;
  margin-bottom: 16px;
`

const SYenSymbol = styled.span`
  font-size: 10px;
`

const SGridFour = styled.div`
  padding-bottom: 160px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  // スマホ版
  @media (max-width: 767px) {
  grid-template-columns: repeat(1, 1fr);
  }
`

const SGridItem = styled.section`
  > h3 {
    font-weight: bold;
    padding-bottom: 6px;
    margin-bottom: 16px;
    border-bottom: 1px solid #666;
  }
  padding: 24px;
  border-radius: 24px;
  background-color: white;
  min-height: 300px;
`

const SDivCenter = styled.div`
  text-align: center;
  h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 60px;
  }
  > p {
    margin-bottom: 60px;
  }
`

export default Architecture;