import React from 'react';
import styled from '@emotion/styled';

import SpringImg from '../../static/img/Spring.png';
import NishikiAE from '../../static/img/NishikiAE.gif';
import MajimunGif from '../../static/img/Majimun.gif';
import HeartShapeThree from '../HeartShapeThree';
import ScrollText from '../../Atoms/ScrollText';

function AboutHero() {
  return (
    <div>
      <SContainer>
        <STextContainer>
          <h2>ABOUT</h2>
        </STextContainer>

        <SScrollContainer>
          <ScrollText />
        </SScrollContainer>

        <STextContainer>
          <HeartShapeThree />
          <SHorizonGrid>
          <p>
            Tokyo Design Horisons は、3DCG、Web、ネイティブアプリ、XR、コーポレートアイデンティファイ、映像制作など、様々なデザインに対応しています。
            <br />私たちは、お客様のビジネスを成功に導くことを目的として、最高のクオリティを提供します。
          </p>
          <p>
            Tokyo Design Horizons handles various design fields, including 3DCG, web, native apps, XR, corporate identity, and video production.
            <br />Our goal is to provide the highest quality to lead our clients' businesses to success.
          </p>
          </SHorizonGrid>
        </STextContainer>
      </SContainer>
    </div>
  );
}

const SContainer = styled.section`
  background-color: #e30000;
  padding: 200px 0 200px 0;
  color: white;
  @media (max-width: 767px) {
    padding: 100px 0;
  }
`

const STextContainer = styled.div`
  width: 75%;
  margin: 0 auto;
  > h2 
  {
    text-align: right;
    font-size: 100px;
    font-weight: bold;
  }
  > p
  {
    margin: 32px 0;
    width: 25em;
    font-size: 16px;
  }

  @media (max-width: 767px) {
    width: 100%;
    > h2 {
      font-size: 52px;
      margin-right: 16px;
    }
  }
`

const SScrollContainer = styled.div`
  padding: 60px 0;

  @media (max-width: 767px) {
    padding: 32px 0;
  }
`

const SHorizonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5em;
  font-family: 'Noto Serif JP', serif;
  @media (max-width: 767px) {
    padding: 0 8px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5em;
  }
`

export default AboutHero;
