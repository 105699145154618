
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Outlet, Link } from 'react-router-dom';
import styled from '@emotion/styled';

interface Post {
  id: number;
  link: string;
  title: { rendered: string };
  excerpt: { rendered: string };
}

const PostsIndex: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get<Post[]>('https://coiai.net/wp-json/wp/v2/posts');
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching WordPress posts:', error);
      }
    };

    fetchPosts();
  }, []);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <SContainer>
      <h1>Posts</h1>
      <SGridContainer>
        {posts.map(post => (
          <SGridCard key={post.id}>
            <Link to={`/posts/${post.id}`}>
              <h4>{post.title.rendered}</h4>
              <p dangerouslySetInnerHTML={{ __html: truncateText(post.excerpt.rendered, 60) }} />
            </Link>
          </SGridCard>
        ))}
      </SGridContainer>
      <Outlet />
    </SContainer>
  );
};

const SContainer = styled.div`
  width: 100% - 32px;
  max-width: 880px;
  padding: 0 16px;
  margin: auto;
  > h1 {
    margin: 200px 0;
  }
`

const SGridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;

  // スマホ版
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const SGridCard = styled.section`
  height: 200px;
  padding: 16px;
  border: 1px solid #ccc;

  h4 {
    font-weight: bold;
    margin-bottom: 8px;
  }

  > a {
    text-decoration: none;
    color: #000;
  }
  > p {
    font-size: 12px;
  }
`

export default PostsIndex;

