import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import styled from '@emotion/styled';

import Top from './Components/pages/Top';
import Navigation from './Components/layout/Navigation';
import PrivacyPolicy from './Components/pages/PrivacyPolicy';
import Posts from './Components/pages/Posts';
import PostDetail from './Components/pages/PostDetail';
import PostsIndex from './Components/pages/PostsIndex';
import Architecture from './Components/pages/Architecture';

function App() {
  return (
    <SBaseContainer>
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route index path="/" element={<Top />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/architecture" element={<Architecture />} />
        <Route path="/posts" element={<Posts />}>
          <Route index element={<PostsIndex/>} />
          <Route path=":postId" element={<PostDetail />} />
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: '1rem' }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
    </SBaseContainer>
  );
}

const SBaseContainer = styled.section`
`

export default App;
