import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
// image
import PCAppLogo from '../../../static/img/AboutImg/PCAppLogo.png';
import PCCD from '../../../static/img/AboutImg/PCCD.png';
import PCMedia from '../../../static/img/AboutImg/PCMedia.png';
import PCWeb from '../../../static/img/AboutImg/PCWeb.png';
import SPAppLogo from '../../../static/img/AboutImg/SPAppLogo.png';
import SPCD from '../../../static/img/AboutImg/SPCD.png';
import SPMedia from '../../../static/img/AboutImg/SPMedia.png';
import SPWeb from '../../../static/img/AboutImg/SPWeb.png';
import PCNative from '../../../static/img/AboutImg/PCNative.png';
import SPNative from '../../../static/img/AboutImg/SPNative.png';
// video
import PCXR from '../../../static/img/AboutImg/PCXR.mp4';
import SPXR from '../../../static/img/AboutImg/SPXR.mp4';

const ServiceColumn: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
    useEffect(() => {
        videoRef.current?.play();
    }, []);

  return (
    <div>
      <STitleContainer>
        <h2>Services</h2>
        <h3>Available on various stores</h3>
      </STitleContainer>

      {/* mapを使う */}
      {services.map((service, index) => (
        <SServiceContainer key={index}>
          <SServiceContent>
            <h3>{service.title}</h3>
            <h4>{service.subtitle}</h4>
            <SServiceDiscription>
              <p>{service.description}</p>
              <p>{service.englishDescription}</p>
            </SServiceDiscription>
          </SServiceContent>
          <SServiceImage>
            {/* 'XR'の場合は動画を表示、それ以外は画像を表示 */}
            {service.title === 'XR' ? (
              <SVideo autoPlay loop muted ref={videoRef}>
                {/* switch sp or pc */}
                {service.video && (
                  <>
                    <SPCSource src={service.video.PC} type="video/mp4" />
                    <SSPSource src={service.video.SP} type="video/mp4" />
                  </>
                )}

                Your browser does not support the video tag.
              </SVideo>
            ) : (
              <>
                <SPCImg src={service.img.PC} alt={service.title} />
                <SSPImg src={service.img.SP} alt={service.title} />
              </>
            )}
          </SServiceImage>
        </SServiceContainer>
      ))}
      
    </div>
  );
}

const STitleContainer = styled.div`
  width: 75%;
  margin: 200px auto;
  margin-bottom: 400px;
  text-align: left;
  > h2 
  {
    font-size: 100px;
    font-weight: bold;
  }
  > h3
  {
    color: #1971ff;
  }

  @media (max-width: 767px) {
    min-height: 80vh;
    margin: 00px 0 100px 0;
    padding: 0 16px;
    > h2 {
      font-size: 52px;
    }
  }
`

const SServiceContainer = styled.div`
  margin-bottom: 3em;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 220px;

  @media (max-width: 767px) {
    width: calc(100% - 16px);
    padding: 0 8px;
  }
`;

const SServiceContent = styled.div`
  h3 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 1em;
  }
  h4 {
    font-size: 20px;
    color: #1971ff;
    margin-bottom: 1em;
  }
`;

const SServiceDiscription = styled.div`
  font-family: 'Noto Serif JP', serif;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
  margin: 1em 0;
  p {
    max-width: 25em;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    gap: 0.5em;
  }
`;

const SServiceImage = styled.div`
  width: 100%;
`;

const SVideo = styled.video`
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  border: 0.7px solid #e0e0e0;
`;

// Video Style
const SPCSource = styled.source`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`;

const SSPSource = styled.source`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

// IMG Style
const SPCImg = styled.img`
  width: 100%;
  object-fit: cover;
  border: 0.7px solid #e0e0e0;
  border-radius: 8px;

  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`;

const SSPImg = styled.img`
  width: 100%;
  object-fit: cover;
  border: 0.7px solid #e0e0e0;
  border-radius: 8px;

  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

const services = [
  {
    title: 'XR',
    subtitle: '3D Object Modeling, XR Apps',
    description: '様々なプラットフォームでの成功事例から、リアルなキャラクターモデリングから本番環境への実装まで、幅広いニーズにお応えします。',
    englishDescription: 'From successful cases on various platforms, we respond to a wide range of needs, from realistic character modeling to implementation in production environments.',
    img: { PC: PCWeb, SP: SPWeb },
    video: { PC: PCXR, SP: SPXR }, 
  },
  {
    title: 'Web',
    subtitle: 'WebサイトからWebアプリまで',
    description: 'ユーザーフレンドリーなデザインと効果的な機能を組み合わせ、魅力的で使いやすいWebサイトやアプリを提供します。SEO対策も徹底的にサポート。',
    englishDescription: 'We provide attractive and easy-to-use websites and apps by combining user-friendly design with effective features. We also provide thorough support for SEO measures.',
    img: { PC: PCWeb, SP: SPWeb }
  },
  {
    title: 'iOS & Android',
    subtitle: 'ネイティブアプリの開発',
    description: 'iOSとAndroid向けのアプリ制作で培った経験と知識で、ユーザーに愛されるアプリを提供。クロスプラットフォームの開発も得意としています。',
    englishDescription: 'We provide apps loved by users with the experience and knowledge gained from app development for iOS and Android. We are also good at cross-platform development.',
    img: { PC: PCNative, SP: SPNative }
  },
  {
    title: 'Corporate Identify',
    subtitle: '会社ロゴからノベリティまで',
    description: '企業全体のブランドイメージの構築に貢献。ロゴデザインから社内用品まで、一貫してプロフェッショナルなデザインをご提供します。',
    englishDescription: 'Contribute to building the overall brand image of the company. We provide professional design consistently from logo design to internal supplies.',
    img: { PC: PCCD, SP: SPCD }
  },
  {
    title: 'Media',
    subtitle: '映像・音響・配信',
    description: 'プロモーションビデオの制作や、配信のサポートまで。映像・音響・配信のプロフェッショナルが、あなたのビジネスをサポートします。',
    englishDescription: 'From the production of promotional videos to support for distribution. Professionals in video, sound, and distribution support your business.',
    img: { PC: PCMedia, SP: SPMedia }
  },
  {
    title: 'App Logos',
    subtitle: 'スタイルガイドに根差した設計思想',
    description: 'アプリロゴはアプリの印象を左右する重要な要素。ユーザーの心をつかむ個性的で魅力的なロゴデザインを提供します。',
    englishDescription: 'App logos are an important element that influences the impression of the app. We provide unique and attractive logo designs that capture the hearts of users.',
    img: { PC: PCAppLogo, SP: SPAppLogo }
  },
];


export default ServiceColumn;
