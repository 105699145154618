
import React from 'react';
import styled from '@emotion/styled';

import SpringImg from '../../static/img/Spring.png';
// image
import CamAppIcon from '../../../static/img/just-full-screen-cam.png';
import FolderAppIcon from '../../../static/img/folder-icon-customizer.png';
import BingIcon from '../../../static/img/BingIcon.png';
import BearIcon from '../../../static/img/BearIcon.png';

function NowOnRelease() {
  return (
    <div>
      <STitleContainer>
        <h2>Now Available</h2>
        <h3>Available on various stores</h3>
      </STitleContainer>

      <SFlexContainer>
        {apps.map((app, index) => (
          <SFlexItem>
          <img src={app.icon} alt="iOS App Store" />
          <SFlexItemText>
            <h3>{app.name}</h3>
            <p>{app.description}</p>
            <p>{app.englishDescription}</p>
            <a key={index} href={app.link} target="_blank" rel="noopener noreferrer">Get on Store</a>
          </SFlexItemText>
          </SFlexItem>
        ))}
      </SFlexContainer>
    </div>
  );
}

const apps = [
  {
    name: 'Just Full Screen Cam',
    description: '配信用にフルスクリーンでの カメラビューが可能なユーティリティApp',
    englishDescription: 'A utility app that allows you to view the camera in full screen for streaming',
    icon: CamAppIcon,
    link: 'https://apps.apple.com/us/app/just-full-screen-cam/id6474943272?itsct=apps_box_link&itscg=30200',
  },
  {
    name: 'Folder Icon Customizer',
    description: 'フォルダのアイコンをカスタマイズ ユーティリティApp',
    englishDescription: 'Utility app to customize folder icons',
    icon: FolderAppIcon,
    link: 'https://apps.apple.com/us/app/folder-icon-customizer/id6474659294?mt=12&itsct=apps_box_link&itscg=30200',
  },
  {
    name: 'Bing Chat AI フォントを変えたい',
    description: 'Bingのフォントを標準に戻すChrome拡張機能',
    englishDescription: 'Chrome extension to restore Bing font to default',
    icon: BingIcon,
    link: 'https://chromewebstore.google.com/detail/bing-chat-ai-%E3%83%95%E3%82%A9%E3%83%B3%E3%83%88%E3%82%92%E5%A4%89%E3%81%88%E3%81%9F%E3%81%84/gkjcpokolmabndhaonaepkchioaldhgi?hl=ja',
  },
  {
    name: 'しろくまガラスのテーマ',
    description: 'ガラス風のテクスチャを目指したChromeのテーマ',
    englishDescription: 'Chrome theme aiming for a glass-like texture',
    icon: BearIcon,
    link: 'https://chromewebstore.google.com/detail/%E3%81%97%E3%82%8D%E3%81%8F%E3%81%BE%E3%82%AC%E3%83%A9%E3%82%B9%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E/nphlmbbfhfkokmphhjkdbnijmjhgbkod?hl=ja',
  },
];

const STitleContainer = styled.div`
  width: 75%;
  margin: 200px auto;
  margin-bottom: 400px;
  text-align: left;
  > h2 
  {
    font-size: 100px;
    font-weight: bold;
  }
  > h3
  {
    color: #1971ff;
  }

  @media (max-width: 767px) {
    min-height: 80vh;
    margin: 100px 0;
    padding: 0 16px;
    > h2 {
      font-size: 52px;
    }
  }
`

const SFlexContainer = styled.div`
  width: 75%;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  gap: 3em;

  @media (max-width: 767px) {
    width: calc(100% - 16px);
    margin: 40px auto;
    padding: 0 8px;
  }
`

const SFlexItem = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    width: 200px;
    object-fit: cover;
  }

  @media (max-width: 767px) {
    margin-top: 5em;
    flex-direction: column;
    img {
      width: 50%;
      margin: 0 auto;
      border-radius: 8px;
    }
  }
`

const SFlexItemText = styled.div`
  margin-top: 2px;
  width: 25em;
  > h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  > p {
    color: #4d4e4f;
    font-size: 16px;
    font-family: 'Noto Serif JP', serif;
    margin-bottom: 6px;
  }
  > a {
    color: #1971ff;
    font-size: 14px;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    width: 100%;
    h3 {
      margin-top: 4em;
    }
  }
`

export default NowOnRelease;
