import React from 'react';
import styled from '@emotion/styled';
import TopCover from '../layout/Top/TopCover';
import HeartShapeThree from '../layout/HeartShapeThree';

import TopHero from '../layout/Top/TopHero';
import AboutHero from '../layout/Top/AboutHero';
import NowOnRelease from '../layout/Top/NowOnRelease';
import ServiceColumn from '../layout/Top/ServiceColumn';

const Top = () => {
  return (
    <div>
      <TopHero />
      <AboutHero />
      <NowOnRelease />
      <ServiceColumn />
    </div>
  );
};

export default Top;
