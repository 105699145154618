import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';

interface PostDetailProps {
  postId: string;
}

const PostDetail: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<any>({});

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`https://coiai.net/wp-json/wp/v2/posts/${postId}`);
        setPost(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching WordPress post:', error);
      }
    };

    fetchPost();
  });

  return (
    <SContainer>
      <h1>{post.title?.rendered}</h1>
      <div dangerouslySetInnerHTML={{ __html: post.content?.rendered }} />
    </SContainer>
  );
};

const SContainer = styled.div`
  width: 100% - 32px;
  max-width: 880px;
  margin: auto;
  padding: 20px;

  img {
      max-width: 100%;
      height: auto;
  }

  h1 {
    margin: 100px 0;
  }

  h2 {
    font-weight: bold;
    margin: 80px 0 16px;
  }

  h3 {
    font-weight: bold;
    margin: 80px 0 16px;
  }

  figure {
    margin: 60px 0;
    iframe {
      width: 100%;
      height: 500px;
    }
  }

  a {
      color: #3498db; /* 青色 */
      text-decoration: none;

      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;

      &:hover {
        color: #2980b9; /* 濃い青色 */
      }
  }

  .wp-block-code {
    margin: 20px 0;
    padding: 28px 20px;
    border-radius: 8px;
    font-family: 'Courier New', Courier, monospace;
    background-color: black;
    color: white;
    code {
      white-space: pre-wrap;
    }   
}


`;

export default PostDetail;
