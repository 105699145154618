import React from 'react';
import styled from '@emotion/styled';

const PrivacyPolicy = () => (
<SContainer>
  <div>
    <h1>プライバシーポリシー（個人情報保護方針）</h1>
    <p>
      コイアイデザイン（以下、「当社」という。）は，ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。
    </p>

    <h2>１．事業者情報</h2>
    <p>事業者名：コイアイデザイン <br />
      代表者：服部陽良<br />
      連絡先情報： coiaichan@gmail.com
    </p>

    <h2>２．個人情報の取得方法</h2>
    <p>当社はユーザーが利用登録をするとき、氏名・生年月日・住所・電話番号・メールアドレスなど個人を特定できる情報を取得させていただきます。<br />
      お問い合わせフォームやコメントの送信時には、氏名・電話番号・メールアドレスを取得させていただきます。</p>

    <h2>３．個人情報の利用目的</h2>
    <p>取得した閲覧・購買履歴等の情報を分析し、ユーザー別に適した商品・サービスをお知らせするために利用します。また、取得した閲覧・購買履歴等の情報は、結果をスコア化した上で当該スコアを第三者へ提供します。</p>

    <h2>４．個人データを安全に管理するための措置</h2>
    <p>
      当社は個人情報を正確かつ最新の内容に保つよう努め、不正なアクセス・改ざん・漏えい・滅失及び毀損から保護するため全従業員及び役員に対して教育研修を実施しています。また、個人情報保護規程を設け、現場での管理についても定期的に点検を行っています。
    </p>

    <h2>５．個人データの共同利用</h2>
    <p>当社は、以下のとおり共同利用を行います。</p>
    <p>個人データの管理に関する責任者<br />
      コイアイデザイン<br />
      共同して利用する者の利用目的<br />
      上記「利用目的」の内容と同様。<br />
      利用項目<br />
      氏名、住所、電話番号、メールアドレス<br />
      共同して利用する者の範囲<br />
      当社企業グループを構成する企業</p>

    <h2>６．個人データの第三者提供について</h2>
    <p>当社は法令及びガイドラインに別段の定めがある場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。</p>

    <h2>７．保有個人データの開示、訂正</h2>
    <p>当社は本人から個人情報の開示を求められたときには、遅滞なく本人に対しこれを開示します。個人情報の利用目的の通知や訂正、追加、削除、利用の停止、第三者への提供の停止を希望される方は以下の手順でご請求ください。<br />
      coiaichan@gmail.com</p>

    <h2>８．個人情報取り扱いに関する相談や苦情の連絡先</h2>
    <p>当社の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせはお問い合わせフォームよりご連絡ください。</p>

    <h2>９．SSL（Secure Socket Layer）について</h2>
    <p>当社のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。</p>

    <h2>１０．cookieについて</h2>
    <p>
      cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当社Webサイトを利用することができます。当社Webサイトがcookieとして送るファイルは、個人を特定するような情報は含んでおりません。<br />
      お使いのWebブラウザの設定により、cookieを無効にすることも可能です。</p>

    <h2>１１．プライバシーポリシーの制定日及び改定日</h2>
    <p>制定：2023/06/12</p>

    <h2>１２．免責事項</h2>
    <p>当社Webサイトに掲載されている情報の正確性には万全を期していますが、利用者が当社Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。<br />
      当社は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。</p>

    <h2>１３．著作権・肖像権</h2>
    <p>当社Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されています。無断での使用や転用は禁止されています。</p>

    <h2>１４．リンク</h2>
    <p>当社Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンクの設置をお断りすることがあります。</p>

  </div>
  <div>
    <h1>Privacy Policy (Personal Information Protection Policy)</h1>
    <p>Coiai Design (hereinafter referred to as “our company”) establishes the following privacy policy (hereinafter
      referred to as “this policy”) regarding the personal information of users. This policy explains what personal
      information we collect, how we use and share it, and how you can control your personal information.</p>

    <h2>1. Business operator information</h2>
    <p>Business name: Coiai design<br/>
      Representative: akira1999hatto@hotmail.co.jp Hattori<br/>
      Contact information: coiaichan@gmail.com</p>

    <h2>2. Acquisition method of personal information</h2>
    <p>When a user registers for use, we will acquire information that can identify an individual, such as name, date of
      birth, address, telephone number, and e-mail address.<br/>
      When sending an inquiry form or comment, we will acquire your name, phone number, and email address.</p>

    <h2>3. Purpose of use of personal information</h2>
    <p>We will analyze the acquired browsing/purchase history information and use it to notify users of products and
      services that are suitable for each user. In addition, the obtained information such as browsing and purchase
      history will be converted into a score and provided to a third party.</p>

    <h2>4. Measures for Safe Management of Personal Data</h2>
    <p>We strive to keep personal information accurate and up-to-date, and provide education and training to all
      employees and officers to protect against unauthorized access, falsification, leakage, loss and damage. In
      addition, we have established personal information protection rules and regularly inspect on-site management.</p>

    <h2>5. Joint use of personal data</h2>
    <p>The Company will jointly use personal information as follows.</p>
    <p>Person responsible for management of personal data<br/>
      Coiai design<br/>
      Purpose of use by joint users<br/>
      Same as “Purpose of Use” above.<br/>
      Usage items<br/>
      Name, address, phone number, email address<br/>
      Scope of joint users<br/>
      Companies that make up our corporate group</p>

    <h2>6. Provision of Personal Data to Third Parties</h2>
    <p>We will not provide personal information to a third party without obtaining consent, unless otherwise stipulated
      by laws and guidelines.</p>

    <h2>7. Disclosure and Correction of Retained Personal Data</h2>
    <p>When the person requests disclosure of personal information, we will disclose it to the person without delay. If
      you wish to be notified of the purpose of use of personal information, correct, add, delete, stop using, or stop
      providing to third parties, please follow the procedure below.<br/>
      coiaichan@gmail.com</p>

    <h2>8. Contact point for consultations and complaints regarding the handling of personal information</h2>
    <p>If you have any questions, concerns, complaints, or other inquiries regarding our handling of personal
      information, please contact us using the inquiry form.</p>

    <h2>9. About SSL (Secure Socket Layer)</h2>
    <p>Our website supports SSL and encrypts communication between web browsers and web servers. Personal information
      entered by users, such as name, address, and phone number, is automatically encrypted.</p>

    <h2>10. About cookies</h2>
    <p>A cookie is data sent from a web server to a web browser. The web server can identify the user’s computer by
      referring to the cookie, and the Company’s website can be used efficiently. Files sent by our website as cookies
      do not contain information that identifies individuals.<br/>
      You can disable cookies by setting your web browser.</p>

    <h2>11. Date of enactment and revision of privacy policy</h2>
    <p>Enactment: 2023/06/12</p>

    <h2>12. Disclaimer</h2>
    <p>We make every effort to ensure the accuracy of the information posted on our website, but we do not assume any
      responsibility for any actions that users perform using the information on our website.<br/>
      The Company shall not be held responsible for any damage caused to the user by the use of the Company website by
      the user or any damage caused by the user to a third party.</p>

    <h2>13. Copyright/portrait rights</h2>
    <p>Texts, images, and all content on our website are protected by copyright, portrait rights, etc. Unauthorized use
      or diversion is prohibited.</p>

    <h2>14. Link</h2>
    <p>Feel free to place a link to our website. However, we may refuse to establish a link depending on the content of
      the website.</p>

  </div>
</SContainer>
);

const SContainer = styled.div`
  width: 100% - 32px;
  padding: 0 16px;
  max-width: 880px;
  margin: auto;
  h1 {
    margin: 200px 0;
  }
  h2 {
    font-weight: bold;
    margin: 80px 0 16px;
  }

  // スマホ版
  @media (max-width: 767px) {
    h1 {
      margin: 100px 0;
    }
  }
`

export default PrivacyPolicy;