import React from 'react';
import styled from '@emotion/styled';

import SpringImg from '../../static/img/Spring.png';
import NishikiAE from '../../static/img/NishikiAE.gif';
import MajimunGif from '../../static/img/Majimun.gif';

function TopHero() {
  return (
    <STopContainer>
      <STopTextContainer>
        <p>Tokyo</p>
        <p>Designs</p>
        <p>& Arts</p>
      </STopTextContainer>
    </STopContainer>
  );
}

const STopContainer = styled.section`
  height: 80vh;
  display: flex;
  align-items: center;
`

const STopTextContainer = styled.div`
  width: 75%;
  margin: 0 auto;
  > p {
    font-size: 80px;
    font-weight: bold;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 16px;
    > p {
      font-size: 52px;
    }
  }
`
export default TopHero;
